import { useController, useFormContext } from 'react-hook-form'

import { Card, TextField } from '@shopify/polaris'

import { useAccount } from '../hooks/useAccount'
import { getTranslation } from '../util/getTranslation'

export function SettingsPaymentLabel() {
  const account = useAccount()
  const defaultLabel = getTranslation(
    `Platba objednávky z obchodu ${account.shopName}`,
    `Store order payment ${account.shopName}`,
  )

  const { setValue } = useFormContext()
  const { field } = useController({
    name: 'paymentLabel',
    defaultValue: account.segmentConfig.paymentLabel || '',
  })

  return (
    <Card.Section>
      <TextField
        {...field}
        label={getTranslation('Popis platby pro zákazníka', 'Payment description for a customer')}
        autoComplete=""
        placeholder={defaultLabel}
        onChange={(value) => setValue(field.name, value)}
      />
    </Card.Section>
  )
}
