import '@shopify/polaris/build/esm/styles.css'

import React from 'react'

import { Frame, Page, Stack } from '@shopify/polaris'

import { useBackToShopAction } from '../hooks/useBackToShopAction'
import { useSegment } from '../hooks/useSegment'
import { useSegmentLabel } from '../hooks/useSegmentLabel'
import { createSegmentPicker } from '../util/createSegmentPicker'
import { getTranslation } from '../util/getTranslation'
import { ActivationCard } from './ActivationCard'
import { ActivationStatusBadge } from './ActivationStatusBadge'
import { useAuth } from './AuthContext'
import { BSecureAccountSettings } from './BSecureAccountSettings'
import { BSecureCredentialsForm } from './BSecureCredentialsForm'
import { ComGateAccountSettings } from './ComGateAccountSettings'
import { ComGateActivationFlow } from './ComGateActivationFlow'
import { GoPayAccountSettings } from './GoPayAccountSettings'
import { GoPayCredentialsForm } from './GoPayCredentialsForm'
import { I18nProvider } from './I18nProvider'
import { TAccountActivationStatus } from '@sga/shopify-payments-types'

function App() {
  const { account, refreshAccount } = useAuth()
  const title = getTranslation('Konfigurace platební brány ', 'Configuration of payment gateway ') + useSegmentLabel()

  React.useEffect(() => {
    document.title = title
  }, [title])

  const pickBySegment = createSegmentPicker(useSegment())
  const auth = useAuth()

  const [status, setStatus] = React.useState<TAccountActivationStatus>(account.activationStatus)

  const settingsGoBackHandler = () => {
    setStatus('new')
  }

  const onCredentialsVerified = React.useCallback(async () => {
    const account = await refreshAccount()
    setStatus(account.activationStatus)
  }, [refreshAccount])

  return (
    <I18nProvider>
      <Frame>
        <Page
          narrowWidth
          title={title}
          titleMetadata={<ActivationStatusBadge />}
          subtitle={getTranslation('Pro obchod ', 'For the shop ') + auth.account.shopName}
          secondaryActions={[useBackToShopAction()]}
        >
          <Stack vertical>
            <ActivationCard
              CredentialsForm={pickBySegment([GoPayCredentialsForm, ComGateActivationFlow, BSecureCredentialsForm], {
                onSuccess: onCredentialsVerified,
              })}
              setStatus={setStatus}
              status={status}
            />
            {React.createElement(
              pickBySegment([GoPayAccountSettings, ComGateAccountSettings, BSecureAccountSettings], {
                goBack: settingsGoBackHandler,
                status,
              }),
            )}
          </Stack>
        </Page>
      </Frame>
    </I18nProvider>
  )
}

export default App
