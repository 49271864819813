import { useSegment } from './useSegment'

export function useSegmentLabel() {
  const segment = useSegment()
  switch (segment) {
    case 'gopay':
      return 'GoPay'
    case 'comgate':
      return 'ComGate'
    case 'bsecure':
      return 'bSecure'
    default:
      return ''
  }
}
